/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useCallback } from "react";
import loadable from "@loadable/component";
const Lottie = loadable(() => import("react-lottie"), {ssr: false});
import PropTypes from "prop-types";
import styles from "./styles.css";
import { useHistory } from "react-router";

const EGCPriceView = loadable(() => import("../egc-price-view"));
const KbbPriceScreen = loadable(() => import("../../shared/kbb-price-screen/component"));
import WeeklyPaymentModal from "../weekly-payment-modal";

import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import {  getCarDetailsPageURLWithoutParam, getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import withWishlist from "../with-wishlist";
import TrackableCarCardItem from "../trackable-car-card-item";
import carListingEventDataParser, { parseFilterToDimension } from "../../../tracking/parser/car-listing";
import carListingEventDataParserGA4, { parseFilterToDimensionGA4 } from "../../../tracking/parser/car-listing-ga4";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { INVENTORY_CAR_CARD_LABELS, NUMBER, PAGE_SOURCE, SALE_KEY, SPECIALPAGETYPE  } from "../../../constants/app-constants";
import { GUEST_POPUP_TYPES } from "../../../au-c2b/constants/app-constants";
import DiscountIcon from "./images/discount-icon.svg";
import { FB_EVENTS_CATEGORY } from "../../../tracking/fb-events";

import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import BlueInfo from "./images/blue-info.png";
import TrendingImage from "./images/trending-down.svg";
import getCarInfo from "../../../utils/helpers/get-car-info";
import LazyImage from "../../shared/lazy-image";
import { getWishlistLottieOptions } from "./helper";
import { Helmet } from "react-helmet";
import SoldBadge from "./images/sold-out.png";
import ReservedIcon from "./images/lock.png";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import TestDriveBadge from "./images/car.png";
import { getAbExpirementVariant } from "../../../utils/helpers/get-ab-expirement-variant";
import { LOGIN_SOURCE } from "../../../au-c2b/constants/app-constants";
import { LOGIN_TYPE } from "../../../constants/checkout-constants";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { GALLERY_TAB_TYPE } from "../car-detail-info/constant";
import HowWeDetermineMarketPriceModal from "../how-we-determine-market-price-modal/component";
// import PriceDealLabel from "../price-deal-label";
import { GA_EVENTS } from "./tracking";
import { getKbbSliderData } from "../../../utils/helpers/kbb-slider-data";
import { cmsURl } from "../../../constants/url-constants";
import InfoIcon from "./images/info-icon-white.svg";
import ComingSoon from "./images/coming-soon.svg";
import { smallImage } from "../../../constants/image-constant";
import isTestDriveEnabledState from "../../../utils/helpers/is-test-drive-enabled-state";
import useUserGeoLocation from "../../../hooks/use-user-geo-location";

const InventoryCarCard = ({
    booked = false,
    appointmentId: carId,
    make,
    model,
    egc,
    price,
    variant,
    state,
    fuelType,
    bodyType = "",
    config = {},
    onClickCallback = () => { },
    transmissionType,
    emiDetails,
    isLoggedIn,
    openLoginPopupConnect,
    setOnLoginSuccessCallback,
    secureToken,
    isWishlistedCar = () => { },
    isAdding,
    onClickAddToWishList,
    gtmFrom,
    allFilters,
    appliedFilters,
    selectedSort,
    item,
    index,
    impressionSource,
    comingSoon = false,
    egcRenderType,
    EgcComponent,
    listingActive,
    saveCheckoutSourcePathnameConnect,
    personalised,
    selectedCity = {},
    cardType = "COMMON",
    tradeInToggleSelected,
    userTradeInData,
    kbbAskingPrice,
    listingType,
    email,
    preload,
    saleConfig,
    similarCarIndex,
    fetchCarDetailsConnect,
    prePopulateCarDetailsConnect,
    setLoginTypeConnect,
    setGsGalleryDataConnect,
    ampPageSource,
    guestLoginProps,
    hideImageBorder = false,
    acceptTradeIn,
    clearSimilarCarListConnect,
    financeFirstCheckoutRevamp,
    cdpAppId,
    webPriceDropMsite,
    isPriceDrop = false
    // isUserZeroDpVariant
}) => {
    const history = useHistory();
    const [showEcgPrice, setShowEcgPrice] = useState(false);
    //const [showPriceBreak, setShowPriceBreak] = useState(false);
    //const [egcData, setEgcData] = useState({});
    const showPriceDropVariant = getAbExpirementVariant(webPriceDropMsite, EXPERIMENT_TYPE.VARIANT_B);
    const [showAddAnotherCarToast, setAddAnotherCarToast] = useState(false);
    const [showKBBModal, setShowKBBModal] = useState(false);
    const [ showCardBasedOnToggle, setShowCardBasedOnToggle] = useState(false);
    const [showKBBRevampedModal, setShowKBBRevampedModal] = useState(false);
    const [hasVisited, setHasVisited] = useState(false);
    const {key: saleKey} = saleConfig?.data || {};

    const defaultSale =  (price - egc) >= NUMBER.THREE_HUNDRED;

    const { tradeIn = false } =  userTradeInData || {};
    const { estimatedPrice = 0, kbbValueProvidedOn } = item || {};
    const isSimilarCar = similarCarIndex > -1;

    const showS20GalleryVariant = false;
    const userGeoData = useUserGeoLocation();
    const { state: { name: userState = {}} = {}} = userGeoData || {};
    // showTestDriveV2 scaled to VARIANT B
    // const showTestDriveVariant = getAbExpirementVariant(showTestDriveV2, EXPERIMENT_TYPE.VARIANT_B) && isTestDriveEnabledState(state, userState);
    const showTestDriveVariant = true && isTestDriveEnabledState(state, userState);

    const { carFullName, carImage: imageUrl, kilometerLabel, carAbsoluteURL, carRelativeURL, priceLabel, carAltTag, carLabel, initialListingPrice } = getCarInfo(item, config);

    const kbbSliderData = getKbbSliderData(item);
    const comingSoonImage = smallImage[bodyType.toLowerCase()] || smallImage.sedan;
    const {
        emiPerWeek = 0
    } = emiDetails || {};

    const kbbMarketPrice = kbbAskingPrice - egc;
    const showKbbMarketPrice = egc < kbbAskingPrice && (kbbMarketPrice) >=  NUMBER.FIVE_HUNDRED && kbbMarketPrice < NUMBER.TEN_THOUSAND ? kbbMarketPrice : false;

    const {search} = history.location;
    const queryParams = parseLocationSearchParams(search);
    const searchQuery = queryParams.search;

    const showPriceDropElements = showPriceDropVariant && isPriceDrop;

    const handleGetPreApprovalClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push("/financing/get-pre-approval");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const handleGetPreApprovalClick = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     history.push("/financing/get-pre-approval");
    // };
    useEffect(() => {
        if (showAddAnotherCarToast) {
            // eslint-disable-next-line no-undef
            setTimeout(() => {
                setAddAnotherCarToast(false);
            }, NUMBER.THREE_THOUSAND);
        }
    }, [showAddAnotherCarToast]);

    useEffect(() => {
        const toggleValue =   (!!(tradeIn && tradeInToggleSelected) || ampPageSource === PAGE_SOURCE.SELL_VALUATION_PAGE);
        setShowCardBasedOnToggle(toggleValue);
    }, [ampPageSource, tradeIn, tradeInToggleSelected]);

    const filterDimensions = parseFilterToDimension(allFilters, appliedFilters, selectedSort);
    const filterDimensionsGA4 = parseFilterToDimensionGA4(allFilters, appliedFilters, selectedSort);

    const ecommerEventData = () => {
        const data = carListingEventDataParser(item, {
            position: index + 1,
            list: impressionSource,
            filterDimensions
        });
        return data;
    };

    const eventData = () => {
        const data = ecommerEventData();
        return {
            name: AU_MOBILE_EVENTS.CAR_IMPRESSION,
            data: {
                ...data.carsImpression
            }
        };
    };

    const eventDataGA4 = () => {
        const dataGA4 = carListingEventDataParserGA4(item, {
            position: index + 1,
            listingType,
            filterDimensions: filterDimensionsGA4
        });
        return {
            name: AU_MOBILE_EVENTS.VIEW_ITEM_LIST,
            data: {
                user_id: email,
                ...dataGA4
            }
        };
    };

    const trackBelowMarketprice = () => {
        if (showKbbMarketPrice) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.LISTING_DISCOUNT_VISIBLE, {eventLabel: `${showKbbMarketPrice}_${carId}`});
        }

        if (isSimilarCar) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CARS24_LISTING_PAGE, {eventAction: "similar_cars", eventLabel: "similar_car_section_seen"});
        }
    };

    const trackCardClickEvent = async() => {
        const data = carListingEventDataParser(item, {
            position: index + 1,
            list: impressionSource,
            filterDimensions
        });

        const dataGA4 = carListingEventDataParserGA4(item, {
            filterDimensions: filterDimensionsGA4,
            listingType,
            position: index + 1
        });
        await yieldToMain();
        trackMobileCustomEventsAU("clearEccomerce", { ecommerce: null });
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.SELECT_ITEM, {
            user_id: email,
            ...dataGA4
        });

        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CAR_CLICK, data.carClick);
        if (cardType === SPECIALPAGETYPE.LOCATION_BASED) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.LISTING_PAGE, {
                eventAction: `car_listing_card_open_${(selectedCity || {}).name}`,
                eventLabel: "{{view_details}}"
            });
        }

        if (searchQuery) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.LISTING_PAGE, {
                eventAction: "search_result_click",
                eventLabel: `Click Position: ${index + 1} Appointment ID: ${carId}`
            });

        }
    };

    const onCardClickHandler = (e) => {
        const prevPath = history.location.pathname;
        onClickCallback(e, item);
        trackCardClickEvent();
        if (cdpAppId !== item.appointmentId) {
            clearSimilarCarListConnect();
        }
        prePopulateCarDetailsConnect(item);
        fetchCarDetailsConnect(carId, {showS20GalleryVariant}, true);
        setGsGalleryDataConnect({
            isOpen: false,
            activeTab: GALLERY_TAB_TYPE.exterior,
            selectedImageIndex: 0,
            clickedImageType: GALLERY_TAB_TYPE.exterior,
            selectedGlobalImageIndex: 0
        });
        if (typeof acceptTradeIn === "function") acceptTradeIn();
        history.push(carRelativeURL, { prevPath });
    };

    const cardClickWrapper = async(e) => {
        e.preventDefault();
        await yieldToMain();
        if (!isLoggedIn && ampPageSource === PAGE_SOURCE.SELL_VALUATION_PAGE) {
            const { setOnGuestEmailVerifyCallback, openGuestLoginConnect} = guestLoginProps;
            openGuestLoginConnect(GUEST_POPUP_TYPES.VERIFY);
            setOnGuestEmailVerifyCallback(() => onCardClickHandler(e));
        } else {
            onCardClickHandler(e);
        }

    };

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onClickWishlistGAEvent = () => {
        if (cardType === SPECIALPAGETYPE.LOCATION_BASED) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CARS24_AU_ALL_PAGES, {
                eventAction: `wishlist_cta_selected_${(selectedCity || {}).name}`,
                eventLabel: "listing_page"
            });
        }
    };

    const onWishlistLoginSuccess = async (token) => {
        const {addtoWishList} = ecommerEventData();
        onClickWishlistGAEvent();
        onClickAddToWishList(
            carId,
            token || secureToken,
            gtmFrom, { comingSoon, make, model },
            {detailsContent: item, detailsConfig: config});
        await yieldToMain();
        trackMobileCustomEventsAU(FB_EVENTS_CATEGORY.ADD_TO_WISHLIST, {content: addtoWishList});

    };

    const onClickWishlist = async(e) => {
        if (e) { preventPropagation(e); }
        await yieldToMain();
        if (isLoggedIn) {
            onWishlistLoginSuccess();
        } else {
            openLoginPopupConnect();
            setLoginTypeConnect(LOGIN_TYPE.WISHLIST);
            setOnLoginSuccessCallback((resp) => onWishlistLoginSuccess((resp || {}).access_token));
            await yieldToMain();

            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.LOGIN_INITIATED, {eventLabel: LOGIN_SOURCE.WISHLIST});
        }
    };

    const getStarted = () => {
        saveCheckoutSourcePathnameConnect(history.location.pathname);
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.STATE_WISE_PRICE_BREAKDOWN_GET_STARTED, {
            eventCategory: "Cars24_listing_page"
        });
        const { relativeURL: checkoutPageUrl } = getCheckoutPageURL({ make, model, appointmentId: carId }, checkoutRoutes.basicDetails.route);
        if (isLoggedIn) {
            const cdpUrl = getCarDetailsPageURLWithoutParam({ make, model, appointmentId: carId });
            const redirectUrl = getAbExpirementVariant(financeFirstCheckoutRevamp, EXPERIMENT_TYPE.VARIANT_B) ? cdpUrl : checkoutPageUrl;
            history.push(redirectUrl);
        } else {
            openLoginPopupConnect();
            setOnLoginSuccessCallback(() => history.push(checkoutPageUrl));
        }
    };

    const handleEcgView = async () => {
        await yieldToMain();
        if (!showEcgPrice) {
            await yieldToMain();
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.STATE_WISE_PRICING_INFO_ICON);
        }
        setShowEcgPrice(!showEcgPrice);
        //if (data) { setEgcData(data); }
        //if (type === "showPriceBreak") { handlePriceBreak(); }
    };

    const openEgcView = async(e) => {
        preventPropagation(e);
        setShowEcgPrice(!showEcgPrice);
        await yieldToMain();
        if (!showEcgPrice) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.STATE_WISE_PRICING_INFO_ICON);
        }

    };

    const getCta = () => {
        const cta = { text: "GET STARTED", action: getStarted, disabled: false };
        cta.disabled = (comingSoon || booked || !listingActive);
        return cta;
    };

    const handleOpenMarketModal = async(e) => {
        preventPropagation(e);
        setShowKBBModal(!showKBBModal);
        await yieldToMain();
        if (!showKBBModal) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.LISTING_BELOW_MARKET_CLICKED, {eventLabel: `${showKbbMarketPrice}_${carId}`});
        }
    };

    const comingSoonInfo = (e) => {
        preventPropagation(e);
        window.open(`${cmsURl()}/pages/coming-soon`, "_blank");
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.COMING_SOON_LISTING_INFO);
    };

    useEffect(() => {
        if (window !== "undefined") {
            if (window.sessionStorage.getItem("ONCE_PER_SESSION") === "null" || isWishlistedCar(carId)) {
                if (isAdding) {
                    window.sessionStorage.setItem("ONCE_PER_SESSION", true); // setting true
                }
                setHasVisited(false); // isStopped = false
            } else {
                setHasVisited(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdding]);

    const showDefaultDiscount = defaultSale && saleKey !== SALE_KEY.BLACK_FRIDAY && saleKey !== SALE_KEY.CHRISTMAS_SALE;

    const handleKbbModal = async (e) => {
        preventPropagation(e);
        if (!showKBBRevampedModal) {

            trackMobileCustomEventsAU(GA_EVENTS.BMP_TAG_CLICKED, {
                ...GA_EVENTS.BMP_TAG_CLICKED,
                eventLabel: carId
            });
            trackMobileCustomEventsAU(GA_EVENTS.BMP_TAG_CLICKED, {
                ...GA_EVENTS.BMP_TAG_CLICKED,
                eventLabel: "clp"
            });
        }
        setShowKBBRevampedModal(!showKBBRevampedModal);
    };

    const renderPriceDropComponent = () => {
        if (showPriceDropElements && carLabel === INVENTORY_CAR_CARD_LABELS.PRICE_DROP) {
            return (<div styleName={"styles.stickerWrapper"}>
                <img src={TrendingImage} alt= "trending image" />
                <button styleName={"styles.priceDrop"}>Price drop</button>
            </div>);
        }
        return null;
    };

    const renderStrikePrice = () => {
        return (
            <React.Fragment>
                {!(showCardBasedOnToggle) || showPriceDropElements}<span styleName={`${showPriceDropElements ? "styles.wasPriceDropLabelStrike" : "styles.tradeInPriceStrike"}`}>{initialListingPrice}</span>
            </React.Fragment>
        );
    };

    const renderCarTag = () => {
        if (showPriceDropVariant) {
            if (carLabel === INVENTORY_CAR_CARD_LABELS.DISCOUNT && showDefaultDiscount && !comingSoon) {
                return (<p styleName={"styles.discountBanners"}>
                    <LazyImage src={DiscountIcon} />
                    {makePriceLabelRound(price - egc)} off</p>);
            } else if (carLabel === INVENTORY_CAR_CARD_LABELS.NEW && showPriceDropVariant) {
                return   (<div styleName={`styles.badgeOuter `}>
                    <p styleName={"styles.text"}>NEW STOCK</p>
                </div>);
            } else return null;
        } else if (showDefaultDiscount && !comingSoon) {
            return (<p styleName={"styles.discountBanners"}>
                <LazyImage src={DiscountIcon} />
                {makePriceLabelRound(price - egc)} off</p>);
        }   else return null;
    };

    return (
        <React.Fragment>
            {preload &&
               <Helmet>
                   <link rel="preload" href={imageUrl} as={"image"} type="image/jpg" fetchPriority="high" />
               </Helmet>}
            <TrackableCarCardItem event={eventData()} eventGA4={eventDataGA4()} eventCallBack={trackBelowMarketprice}>
                <div>
                    <div>
                        <meta content="AUD" />
                        <meta content={price && parseInt(price)} />
                    </div>
                    <React.Fragment>
                        <a  styleName={`styles.wrapper ${hideImageBorder ? "styles.revampBorder" : ""}`} href={carAbsoluteURL} onClick={cardClickWrapper}>
                            <div styleName={"styles.mediaWrapper"} className="media">
                                {/* {
                                    carLabel === INVENTORY_CAR_CARD_LABELS.NEW && showPriceDropVariant &&
                                        <div styleName={`styles.badgeOuter `}>
                                            <p styleName={"styles.text"}>NEW STOCK</p>
                                        </div>
                                }
                                {showDefaultDiscount && carLabel === INVENTORY_CAR_CARD_LABELS.DISCOUNT && !comingSoon && <p styleName={"styles.discountBanners"}>
                                    <LazyImage src={DiscountIcon} />
                                    {makePriceLabelRound(price - egc)} off</p>
                                } */}
                                {renderCarTag()}
                                <div styleName={"styles.imageWrapper"}>
                                    {comingSoon ?
                                        <div styleName="styles.mediaWrapper styles.comingSoonSection">
                                            <img  styleName={"styles.imageComingSoon"} alt={carAltTag} src={comingSoonImage} />
                                            <div styleName={"styles.comingSoonWrap"}>
                                                <img styleName={"styles.comingSoonImg"} src={ComingSoon} alt="Coming Soon" />
                                                <span styleName={"styles.whatText"}>
                                                    <button onClick={comingSoonInfo}>
                                                        <img src={InfoIcon} /> <small styleName={"styles.showText"}>What does this mean?</small>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        : (<React.Fragment>
                                            {showTestDriveVariant && !item.reserved && <div styleName={"styles.testDriveBadgeWrapper"}>
                                                <img src={TestDriveBadge} />
                                                <p styleName={"styles.testDrive"}>Test drive</p>
                                            </div>}
                                            {/* This is for sold cars */}
                                            {(booked || !listingActive) &&
                                            <div styleName={"styles.carSoldWrapper"}>
                                                <LazyImage src={SoldBadge} alt="Sold Out" />
                                            </div>
                                            }
                                            {/* This is for reserved cars */}
                                            {item.reserved && !item.booked &&
                                            <div styleName={"styles.reservedWrapper"}>
                                                <img src={ReservedIcon} />
                                                <p styleName={"styles.reserved"}>RESERVED</p>
                                            </div>
                                            }
                                            {index > NUMBER.THREE ?
                                                <LazyImage src={imageUrl} alt={carAltTag} height="111" width="179" /> :
                                                <img src={imageUrl} alt={carAltTag}  height="111" width="179" />}
                                            <div styleName={"styles.wishlistIcons"}>
                                                <button onClick={onClickWishlist}><Lottie isStopped={hasVisited}  options={getWishlistLottieOptions(isWishlistedCar(carId))} /></button>
                                            </div>
                                        </React.Fragment>)}
                                </div>
                                <div className="media-body">
                                    <p styleName={"styles.carName"}>{carFullName}</p>
                                    <p styleName={"styles.carVariant"}>{variant} {transmissionType}</p>
                                    <p styleName={"styles.perWeek"}>$ {emiPerWeek}/week
                                        <WeeklyPaymentModal
                                            emiDetails={emiDetails}
                                            onCtaClicked={handleGetPreApprovalClick}
                                            personalised={personalised}
                                            isListing={true}
                                            gtmFrom={gtmFrom}
                                        />
                                    </p>
                                    <div styleName="styles.priceEgcWr">
                                        <p styleName={"styles.carPrice"}>
                                            { showCardBasedOnToggle  ? makePriceLabelRound(estimatedPrice) : priceLabel }
                                            { showCardBasedOnToggle && !showPriceDropElements ? <span styleName={"styles.cutOutPrice"}>{"Trade-in price"}</span> : null}
                                        </p>
                                        {(defaultSale || showPriceDropElements) && renderStrikePrice()}
                                    </div>
                                    <p styleName={"styles.Egc"} onClick={openEgcView}>Excl Govt Charges <img src={BlueInfo} width="16" height="16" alt="info icon" /></p>
                                </div>
                            </div>
                            <div styleName={"styles.tabWrapper"}>
                                <div styleName={"styles.tab"}>
                                    <p styleName={"styles.tabName"}>{fuelType}</p>
                                </div>
                                <div styleName={"styles.tab"}>
                                    <p styleName={"styles.tabName"}>{transmissionType}</p>
                                </div>
                                <div styleName={"styles.tab"}>
                                    <p styleName={"styles.tabName"}>{kilometerLabel}</p>
                                </div>
                                {renderPriceDropComponent()}
                            </div>
                        </a>
                    </React.Fragment>
                    {showEcgPrice && <EGCPriceView
                        {...item}
                        onClose={handleEcgView}
                        config={config}
                        type={egcRenderType}
                        EgcComponent={EgcComponent}
                        bodyType={item.body || item.bodyType}
                        cta={getCta()}
                    />}
                    {showKBBModal &&
                    <KbbPriceScreen
                        onClose={handleOpenMarketModal}
                        egc={egc}
                        kbbAskingPrice={kbbAskingPrice}
                        kbbValueProvidedOn={kbbValueProvidedOn}
                    />}
                    <HowWeDetermineMarketPriceModal show = {showKBBRevampedModal} onClose = {handleKbbModal} kbbSliderData={kbbSliderData} />
                </div>
            </TrackableCarCardItem>
        </React.Fragment>
    );
};

InventoryCarCard.propTypes = {
    booked: PropTypes.bool,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    appointmentId: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    price: PropTypes.number,
    odometerReading: PropTypes.number,
    fuelType: PropTypes.string,
    mainImage: PropTypes.object,
    config: PropTypes.object,
    onClickCallback: PropTypes.func,
    transmissionType: PropTypes.string,
    variant: PropTypes.string,
    history: PropTypes.object,
    selectedCity: PropTypes.object,
    specialPageType: PropTypes.string,
    emiDetails: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    openLoginPopupConnect: PropTypes.func,
    setOnLoginSuccessCallback: PropTypes.func,
    secureToken: PropTypes.string,
    isWishlistedCar: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    gtmFrom: PropTypes.string,
    defaultTootlipStyle: PropTypes.string,
    allFilters: PropTypes.object,
    appliedFilters: PropTypes.array,
    selectedSort: PropTypes.object,
    item: PropTypes.object,
    index: PropTypes.number,
    impressionSource: PropTypes.string,
    comingSoon: PropTypes.bool,
    isUserZeroDpVariant: PropTypes.bool,
    bodyType: PropTypes.string,
    handleEcgView: PropTypes.func,
    egcRenderType: PropTypes.string,
    fetchCarDetailsConnect: PropTypes.func,
    EgcComponent: PropTypes.string,
    egc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    listingActive: PropTypes.bool,
    saveCheckoutSourcePathnameConnect: PropTypes.func,
    personalised: PropTypes.bool,
    promotion: PropTypes.bool,
    saleConfig: PropTypes.object,
    mediaSource: PropTypes.string,
    expressDelivery: PropTypes.object,
    cardType: PropTypes.string,
    removeCarFromCompareConnect: PropTypes.func,
    addCarToCompareConnect: PropTypes.func,
    showCarCompare: PropTypes.bool,
    tradeInToggleSelected: PropTypes.bool,
    userTradeInData: PropTypes.object,
    kbbAskingPrice: PropTypes.number,
    email: PropTypes.string,
    listingType: PropTypes.string,
    preload: PropTypes.bool,
    similarCarIndex: PropTypes.number,
    isAdding: PropTypes.bool,
    modelYear: PropTypes.string,
    prePopulateCarDetailsConnect: PropTypes.func,
    state: PropTypes.string,
    setLoginTypeConnect: PropTypes.func,
    setGsGalleryDataConnect: PropTypes.func,
    ampPageSource: PropTypes.string,
    guestLoginProps: PropTypes.object,
    hideImageBorder: PropTypes.bool,
    acceptTradeIn: PropTypes.func,
    clearSimilarCarListConnect: PropTypes.func,
    financeFirstCheckoutRevamp: PropTypes.object,
    cdpAppId: PropTypes.string,
    webPriceDropMsite: PropTypes.object,
    isPriceDrop: PropTypes.bool
};

export default withWishlist(InventoryCarCard);
